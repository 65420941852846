import React from "react";
import styled from "styled-components";
import ContactModal from './ContactModal';
import Modal from 'react-modal';

const HeroContainer = styled.div`
    
`

const TitleWrapper = styled.div`
    scroll-snap-align: start;
    
    background-color: black;
    color: white;
//     background-image: url("https://pinnacle-test-costing-image-bucket.s3.us-west-1.amazonaws.com/pedro-lastra-Nyvq2juw4_o-unsplash-dark.jpg");
    background-position: center;
    background-size: cover;


    height: calc(var(--vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ContentWrapper = styled.div`
    width: 70%;
    text-align: Center;
    @media (max-width: 800px) {
        width: 80%;
    }
`

const Title = styled.h1`
    font-weight: strong;
    font-size: 10em;
    @media (max-width: 800px) {
        font-size: 6em;
    }
    margin-top: 0px;
    margin-bottom: 0px;
`

const Subtitle = styled.p`
    margin-top: 0px;
    font-size: 1.4em;
    @media (max-width: 800px) {
        font-size: 1.0em;
    }
`

const Emphasis = styled.span`
    font-weight: bold;
`

// const SubSubtitle = styled.p`
//     margin-top: 50px;
//     font-size: 1.1em;
//     @media (max-width: 800px) {
//         font-size: 1em;
//     }
// `


const ButtonBase = styled.button`
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
//     border-radius: 30px; 
    font-size: 1.2em;   
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`

const GetInTouchButton = styled(ButtonBase)`
    
    border: 3px solid white;
    background-color: transparent;
    color: white;
    &:hover {
        background-color: white;
        color: black;
    }
`



const Skin = () => {

    const [message,setMessage] = React.useState("");
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setMessage("");
        setIsOpen(false);
    }
    
    const [name,setName] = React.useState("");
    const [company,setCompany] = React.useState("");
    const [role,setRole] = React.useState("");
    const [email,setEmail] = React.useState("");
    
    const onChange = React.useCallback(async(value) => {
        console.log("Captcha value:", value);
    },[]);    
    
    const handleSubmit = React.useCallback(async() => {
        var uri = "https://paulstuart2222.pythonanywhere.com/save_info"
        var p = JSON.stringify({
           name:name,
           company:company,
           role:role,
           email:email
        })
        
        try {
            const response = await fetch(uri
                    ,{
                    method:'POST',
                    mode: 'cors',
                    headers: {
                    'Access-Control-Allow-Origin':'*',
                    'Content-Type':'application/json'
                    },
                    body:p
                });
            if (!response.ok) {
                throw new Error(`HTTP error: ${response.status}`);
            }       
            
            const data = await response.json();
            
            setMessage("Message Received! We'll be in touch soon.");
            
            }
        catch(error) {
            console.error(`Could not get products: ${error}`);
            setMessage("Something went wrong saving your info. Please try again.");
        }
        
    },[name,company,role,email]);
    
    return(
            <TitleWrapper>
                <ContentWrapper>
                    <Title>
                        100%
                    </Title>
                    <Subtitle>
                        <Emphasis>Satisfaction & ROI Guarantee</Emphasis>
                        <br /><br />
                        If your operating margin doesn't improve by at least 2x your investment in us, you don't pay a cent. 
                        <br /><br />Period.
                    </Subtitle>
                    
                </ContentWrapper>
                <GetInTouchButton onClick={openModal}>Get in Touch</GetInTouchButton>
                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                >
                    <ContactModal 
                        name = {name}
                        setName = {setName}
                        company = {company}
                        setCompany = {setCompany}
                        role = {role}
                        setRole = {setRole}
                        email = {email}
                        setEmail = {setEmail}
                        handleSubmit = {handleSubmit}
                        closeModal = {closeModal}
                        message = {message}      
                    />

                </Modal>
            </TitleWrapper>
    );
}

export default Skin;