import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
    scroll-snap-align: start;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
`

const FooterItem = styled.div`
    flex: 1;
`

const Footer = () => {
    return(
        <FooterWrapper>
            <FooterItem>
                Pinnacle Profitability™
                <br /><br />
                All rights reserved
            </FooterItem>
        </FooterWrapper>
    );

}

export default Footer;