import React from "react";
import styled from "styled-components";
import Navigation from "./Navigation";



// height: fill-available;
//     height: -webkit-fill-available;


const TitleWrapper = styled.div`
    
    scroll-snap-align: start;
    
    background-color: black;
    color: white;
    background-image: url("https://pinnacle-test-costing-image-bucket.s3.us-west-1.amazonaws.com/pedro-lastra-Nyvq2juw4_o-unsplash-dark.jpg");
    background-position: center;
    background-size: cover;


    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--vh) * 100);
    
`

const ContentWrapper = styled.div`
    width: 70%;
    text-align: left;
    @media (max-width: 800px) {
        width: 80%;
    }
`

const Title = styled.h1`
    font-weight: strong;
    font-size: 4em;
    @media (max-width: 800px) {
        font-size: 2em;
    }
`

const Subtitle = styled.p`
    font-size: 1.4em;
    @media (max-width: 800px) {
        font-size: 1.1em;
    }
`

const Emphasis = styled.span`
    font-weight: bold;
    font-style: italic;
`




const Hero = () => {
    
    return(
            
        <TitleWrapper>
            <Navigation />
            <ContentWrapper>
                <Title>
                    Maximize Revenue. <br />
                    Contain Cost. <br />
                    Increase Operating Margin. <br />
                </Title>
                <Subtitle>
                    We enable FP&A to anticipate risks and identify opportunities to maximize revenue, contain costs, and improve operating margin.
                </Subtitle>
            </ContentWrapper>
        </TitleWrapper>
    );
}

export default Hero;