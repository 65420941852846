import React from "react";
import styled from "styled-components";


const ModalContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2d3436;
    
`

const ModalContentContainer = styled.div`
    width: 40%;
    max-width: 600px;
    min-height: 400px;
    display: flex;
    flex-direction:column;
    border-radius: 25px;
    background-color: #ecf0f1;
    
    @media (max-width: 1200px) and (min-width: 1001px) {
        width: 60%;
    }
    
    @media (max-width: 1000px) and (min-width: 801px) {
        width: 80%;
    }
    
    @media (max-width: 800px) {
        width: 100%;
    }
`

const ModalTitleContainer = styled.div`
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 50px;
    
    @media (max-width: 1000px) and (min-width: 801px) {
        padding-left: 35px;
        padding-right: 35px;
        margin-top: 35px;
    }
    
    @media (max-width: 800px) {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
    }
`

const ModalTitle = styled.h1`
    font-size: 3em;
    margin-top: 0px;
    margin-bottom: 0px;
//     @media (max-width: 1000px) and (min-width: 801px) {
//         margin-top: 35px;
//         margin-bottom: 5px;
//     }
    
//     @media (max-width: 800px) {
//         margin-top: 20px;
//         margin-bottom: 2px;
//     }
`

const ModalSubTitle = styled.p`
    font-size: 0.9em;
    margin-top: 5px;
    margin-bottom: 0px;
    
//     @media (max-width: 1000px) and (min-width: 801px) {
//         margin-top: 15px;
//     }
//     
//     @media (max-width: 800px) {
//         margin-top: 5px;
//     }
    
`


const ContentRow = styled.div`
    display:flex;
    flex-direction:column;
    height: 50px;
    align-items: left;
    margin-top: 15px;
    
    padding-left: 50px;
    padding-right: 50px;
    
    @media (max-width: 1000px) and (min-width: 801px) {
        padding-left: 35px;
        padding-right: 35px;
    }
    
    @media (max-width: 800px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    
`

const LabelContainer = styled.div`
    flex: 0.5;
    font-weight: bold;
`

const InputContainer = styled.div`
    flex: 1;
    margin-top: 5px;
`

const Input = styled.input`
    width: 97%;
    height: 30px;
    border: 2px solid #b2bec3;
`


const ModalButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    
    @media (max-width: 1000px) and (min-width: 801px) {
        margin-top: 35px;
        margin-bottom: 35px;
    }
    
    @media (max-width: 800px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    
`

const ModalButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
`

const MessageContainer = styled.div`
    text-align: center;
    margin-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
    
    @media (max-width: 1000px) and (min-width: 801px) {
        padding-left: 35px;
        padding-right: 35px;
    }
    
    @media (max-width: 800px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`
const ButtonBase = styled.button`
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
//     border-radius: 30px; 
    font-size: 1.2em;   
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
    width: 150px;
    @media (max-width: 1000px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`


const ModalSubmitButton = styled(ButtonBase)`
    margin-left: 10px;
    margin-right: 10px;
    border: 3px solid #b2bec3;
    background-color: #b2bec3;
    color: white;    
`

const ModalCancelButton = styled(ButtonBase)`
    margin-left: 10px;
    margin-right: 10px;
    border: 3px solid #dfe6e9;
    background-color: #dfe6e9;
    color: black;
`


const ContactModal = ({name,setName,company,setCompany,role,setRole,email,setEmail,handleSubmit,closeModal,message}) => {

    const handleNameChange = React.useCallback((value) => {
        setName(value);
    },[]);
    
    const handleCompanyChange = React.useCallback((value) => {
        setCompany(value);
    },[]);
    
    const handleRoleChange = React.useCallback((value) => {
        setRole(value);
    },[]);


    const handleEmailChange = React.useCallback((value) => {
        setEmail(value);
    },[]);
    

    return(
        <ModalContentWrapper>
            <ModalContentContainer>
                <ModalTitleContainer>
                    <ModalTitle>Work with us</ModalTitle>
                    <ModalSubTitle>We would love to work with you</ModalSubTitle>
                </ModalTitleContainer>
                <ContentRow>
                    <LabelContainer>
                        Name
                    </LabelContainer>
                    <InputContainer>
                        <Input 
                            value = {name}
                            type="text" 
                            onChange={(e) => handleNameChange(e.target.value)}
                        />
                    </InputContainer>
                </ContentRow>
                <ContentRow>
                    <LabelContainer>
                        Company
                    </LabelContainer>
                    <InputContainer>
                        <Input 
                            value = {company}
                            type="text" 
                            onChange={(e) => handleCompanyChange(e.target.value)}
                        />
                    </InputContainer>
                </ContentRow>
                <ContentRow>
                    <LabelContainer>
                        Role
                    </LabelContainer>
                    <InputContainer>
                        <Input 
                            value = {role}
                            type="text" 
                            onChange={(e) => handleRoleChange(e.target.value)}
                        />
                    </InputContainer>
                </ContentRow>
                <ContentRow>
                    <LabelContainer>
                        Work Email
                    </LabelContainer>
                    <InputContainer>
                        <Input 
                            value = {email}
                            type="text" 
                            onChange={(e) => handleEmailChange(e.target.value)}
                        />
                    </InputContainer>
                </ContentRow>
                <ModalButtonWrapper>
                    <ModalButtonContainer>
                        <ModalSubmitButton onClick={handleSubmit}>Submit</ModalSubmitButton>
                        <ModalCancelButton onClick={closeModal}>Close</ModalCancelButton>
                    </ModalButtonContainer>
                    {message.length > 0 && (
                        <MessageContainer>
                            {message}
                        </MessageContainer>
                    )}
                </ModalButtonWrapper>
            </ModalContentContainer>
        </ModalContentWrapper>
        
    );
}

export default ContactModal;