import React from "react";
import styled from "styled-components";

const NavBarWrapper = styled.div`
    height: 100px;
    width: 100vw;
    
    display: flex;
    align-items: center;
    
    
    position: absolute;
    top: 0px;
    left: 0px;
    
    padding-left: 20px;
    
    @media (max-width: 800px) {
        justify-content: center;
        padding-left: 0px;
    }
`


const ImageContainer = styled.div`
    height: 70px;
    @media (max-width: 800px) {
        height: 55px;
    }
`

const Img = styled.img`
    height: 100%;
    width: auto;
`

// const MenuWrapper = styled.div`
//     flex: 1;
//     padding-top: 10px;
//     @media (max-width: 800px) {
//         justify-content: right;
//     }
// `
// 
// const MenuUl = styled.ul`
//     list-style-type: none;
//     display: flex;
//     flex-direction: row;
//     @media (max-width: 800px) {
//         flex-direction: column;
//     }
// `
// 
// const MenuLi = styled.li`
//     display: inline-block;
//     padding-left: 30px;
//     padding-right: 30px;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     font-size: 1.3em;
// `

const Navigation = () => {
    return(
        <NavBarWrapper>
            <ImageContainer>
                <Img src="https://pinnacle-test-costing-image-bucket.s3.us-west-1.amazonaws.com/pinnacle-logo.png" />
            </ImageContainer>            
        </NavBarWrapper>
    );
}

export default Navigation;

// <MenuWrapper>
//                 <MenuUl>
//                     <MenuLi>Home</MenuLi>
//                     <MenuLi>About</MenuLi>
//                 </MenuUl>
//             </MenuWrapper>



//         <ImgLi>
//             <LogoImg src="https://pinnacle-test-costing-image-bucket.s3.us-west-1.amazonaws.com/pinnacle-logo.png" alt="" className="rounded-circle me-2"/>
//         </ImgLi>