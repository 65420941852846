import React from "react";
import styled from "styled-components";


const ContentWrapper = styled.div`
    scroll-snap-align: start;
    
    display: flex;
    flex-direction: row;    
    
    @media (max-width: 1000px) {
        flex-direction: column;
    }
    
    height: calc(var(--vh) * 100);
    
    background-color: white;
    color: black;
`

const TitleContainer = styled.div`
    flex: 1;
    background-color: #dfe6e9;
    color: black;
    
    display: flex;
    align-items: center;
    order: 2;
    @media (max-width: 1000px) {
        order: 1;
    }
`

const TextContainer = styled.div`
    text-align: left;
//     padding-top: 20px;
//     padding-bottom: 20px;
//     background-color: red;
`

const Title = styled.h1`
    margin-top: 10px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 10px;
    font-size: 2em;
    @media (max-width: 1000px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const Subtitle = styled.p`
    padding-left: 50px;
    padding-right: 50px;
    font-size: 1em;
    @media (max-width: 1000px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`

// const InfoContainer = styled.div`
//     grid-column: 1;
//     grid-row: 2;
//     
//     @media (max-width: 1000px) {
//         grid-column: 1;
//         grid-row: 3;
//     }
//     
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     
// `


const ImgContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 1000px) {
        order: 2;
    }
    
`

const Img = styled.img`
    max-width: 90%;
    height: auto;   
    border-radius: 25px;
    border: 1px solid #E8E8E8;
    box-shadow: 2px 2px 4px #E8E8E8;
    
//     @media (max-width: 1000px) and (min-width: 801px) {
//         max-width: 0%;
//     }
//     
//     @media (max-width: 800px) {
//         max-width: 80%;
//     }
`

const Emphasis = styled.span`
    font-weight: bold;
    font-style: italic;
`

const Cost = () => {
    return(
        <ContentWrapper>
            <TitleContainer>
                <TextContainer>
                    <Title>
                        Measure and Contain Costs.
                    </Title>
                    <Subtitle>
                        Discover sources of <Emphasis>maximum operating leverage.</Emphasis> Accurately measure true fixed/variable + direct/indirect/overhead costs at the customer/product/channel level.
                    </Subtitle>
                </TextContainer>
            </TitleContainer>
             <ImgContainer>
                <Img src="https://pinnacle-test-costing-image-bucket.s3.us-west-1.amazonaws.com/pinnacle-cost-savings.png" />
            </ImgContainer>
        </ContentWrapper>
    );
}

export default Cost;
