import logo from './logo.svg';
import './App.css';
import { Hero, Skin, Footer, Revenue, Cost, Margin} from './components'
import styled from "styled-components";

const AppContainer = styled.div `
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    height: calc(var(--vh) * 100);
`

function App() {
    return (
        <div className="App">
            <AppContainer>
                <Hero />
                <Revenue />
                <Cost />
                <Margin />
                <Skin />
                <Footer />
            </AppContainer>
        </div>
    );
}

export default App;

// <header className="App-header">
//             <img src={logo} className="App-logo" alt="logo" />
//             <p>
//                 Edit <code>src/App.js</code> and save to reload.
//             </p>
//             <a
//                 className="App-link"
//                 href="https://reactjs.org"
//                 target="_blank"
//                 rel="noopener noreferrer"
//             >
//                 Learn React
//             </a>
//             </header>